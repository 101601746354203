/// <reference types="@types/googlemaps" />
import { createApp } from 'vue'
import dayjs from 'dayjs'
import ElementPlus from 'element-plus'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

import 'element-plus/theme-chalk/src/index.scss'

import './assets/normalize.css'

import router from './router'

import pinia from './store'

import tokenHandler from './plugins/tokenHandler'

import App from './App.vue'

console.log('process.env.BASE_URL', process.env.BASE_URL)

createApp(App)
  .use(pinia)
  .use(router)
  .use(ElementPlus)
  .use(tokenHandler)
  .mount('#app')
