import { Plugin } from 'vue'

import { setToken } from '@/api/index'

import { useAuthStore } from '@/store/auth'

const tokenHandler: Plugin = {
  install() {
    const token = useAuthStore().session?.token

    if (token) setToken(token)
  },
}

export default tokenHandler
