import { Nullable } from '@/models/common'

export enum LsGlobalKeys {
  AUTH = 'auth',
}

export const lsGet = (key: LsGlobalKeys): string | null =>
  localStorage.getItem(key)

export const lsSave = (
  key: LsGlobalKeys,
  // eslint-disable-next-line
  payload?: Nullable<Record<string, any> | string | any[]>
) => {
  if (!payload) {
    console.log(
      `LocalStorage helper: ommiting to set an empty value for ${key} key`
    )
    return
  }

  localStorage.setItem(key, JSON.stringify(payload))
}

export const lsRemove = (key: LsGlobalKeys) => localStorage.removeItem(key)

export const lsHas = (key: LsGlobalKeys) => lsGet(key) !== null
