/* eslint-disable @typescript-eslint/no-explicit-any */
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'

import { PaginationMeta } from '@/models/api'
import {
  CommonModule,
  ListModule,
  ModuleError,
  ModuleWithData,
  MultipleUpdatesModule,
  PaginationModule,
} from '@/models/store'

export const getInitialCommonModuleState = (): CommonModule => ({
  error: null,
  isLoading: false,
  success: false,
})

export const getMultipleUpdatesModuleState = (): MultipleUpdatesModule<
  any,
  any
> => ({
  error: null,
  loadingList: [],
  successList: [],
})

export const getInitialDataModuleState = (): ModuleWithData<any> => ({
  data: null,
  isLoading: false,
  success: false,
  error: null,
})

export const getInitialListModuleState = (): ListModule<any> => ({
  list: [],
  error: null,
  isLoading: false,
  success: false,
  isInitialLoadingDone: false,
})

export const getPagintaionModuleState = (): PaginationModule<any> => ({
  list: [],
  success: false,
  meta: null,
  error: null,
  isLoading: true,
  isInitialLoadingDone: false,
})

type Action = 'start' | 'success' | 'error'

export const handleCommonModuleState = <
  A extends Action,
  M extends CommonModule<any>
>(
  action: A,
  commonModule: M,
  ...args: A extends 'error' ? [M['error']?] : []
) => {
  switch (action) {
    case 'start': {
      commonModule.error = null
      commonModule.success = false
      commonModule.isLoading = true
      break
    }

    case 'success': {
      commonModule.isLoading = false
      commonModule.success = true
      break
    }

    case 'error': {
      const error = args[0]

      commonModule.isLoading = false
      if (error) commonModule.error = error
      break
    }

    default:
      break
  }
}

export const handleDataModuleState = <
  A extends Action,
  M extends ModuleWithData<any>
>(
  action: A,
  dataModule: M,
  ...[result]: A extends 'start'
    ? []
    : [A extends 'success' ? M['data'] : M['error']]
) => {
  switch (action) {
    case 'start': {
      handleCommonModuleState('start', dataModule)
      break
    }

    case 'success': {
      handleCommonModuleState('success', dataModule)
      dataModule.data = result
      break
    }

    case 'error': {
      handleCommonModuleState('error', dataModule, result)
      break
    }

    default:
      break
  }
}

export const handleListModuleState = <
  A extends Action,
  M extends ListModule<any>
>(
  action: A,
  listModule: M,
  ...[result]: A extends 'success'
    ? [M['list']]
    : A extends 'error'
    ? [string]
    : []
) => {
  switch (action) {
    case 'start': {
      handleCommonModuleState('start', listModule)
      break
    }

    case 'success': {
      handleCommonModuleState('success', listModule)

      listModule.isInitialLoadingDone = true
      if (result && !isString(result)) listModule.list = result
      break
    }

    case 'error': {
      handleCommonModuleState('error', listModule)
      listModule.isInitialLoadingDone = true
      if (isString(result)) listModule.error = result
      break
    }

    default:
      break
  }
}

type ExtractPaginationModuleData<T> = T extends PaginationModule<infer D>
  ? D[]
  : never

export const handlePaginationModuleState = <
  A extends Action,
  T extends PaginationModule<any>,
  D extends ExtractPaginationModuleData<T>
>(
  action: A,
  paginationModule: T,
  ...result: A extends 'start'
    ? []
    : [A extends 'success' ? { list: D; meta: PaginationMeta } : string]
): void => {
  switch (action) {
    case 'start': {
      handleListModuleState('start', paginationModule)
      break
    }

    case 'success': {
      const data = result[0]

      if (isObject(data)) {
        handleListModuleState('success', paginationModule, data.list)
        paginationModule.meta = data.meta
      }
      break
    }

    case 'error': {
      const error = result[0]

      if (isString(error)) {
        handleListModuleState('error', paginationModule, error)
      }
      break
    }

    default:
      break
  }
}

type ExtractMultipleModuleKeys<T> = T extends MultipleUpdatesModule<
  infer Keys,
  ModuleError<string>
>
  ? Keys[]
  : never

export const handleMultipleUpdatesState = <
  A extends Action,
  T extends MultipleUpdatesModule<string, any>,
  K extends ExtractMultipleModuleKeys<T>
>(
  action: A,
  updtModule: T,
  keys: K,
  ...[error]: A extends 'error' ? [T['error']?] : []
): void => {
  switch (action) {
    case 'start': {
      updtModule.error = null
      keys.forEach(key => {
        // Mark each key as loading
        if (!updtModule.loadingList.includes(key)) {
          updtModule.loadingList.push(key)
        }

        // Remove from success if was previously added
        const successIndex = updtModule.successList.indexOf(key)
        if (successIndex !== -1) updtModule.successList.splice(successIndex, 1)
      })

      break
    }

    case 'success': {
      keys.forEach(key => {
        // Add success state for key
        if (!updtModule.successList.includes(key)) {
          updtModule.successList.push(key)
        }

        // Remove loading state for key
        const loadingIndex = updtModule.loadingList.indexOf(key)
        if (loadingIndex != -1) updtModule.loadingList.splice(loadingIndex, 1)
      })

      break
    }

    case 'error': {
      keys.forEach(key => {
        // Remove loading state for key
        const loadingIndex = updtModule.loadingList.indexOf(key)
        if (loadingIndex != -1) updtModule.loadingList.splice(loadingIndex, 1)

        if (error) updtModule.error = error
      })
      break
    }

    default:
      break
  }
}
