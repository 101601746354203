import isString from 'lodash/isString'
import isNil from 'lodash/isNil'
import startCase from 'lodash/startCase'
import capitalize from 'lodash/capitalize'
import isArray from 'lodash/isArray'

import { Primitive } from '@/models/common'

export function commonCase(str: string, capitalized = false) {
  const cased = startCase(str).toLowerCase()
  return capitalized ? capitalize(cased) : cased
}

export function commonCaseCapitalized(str: string) {
  return commonCase(str, true)
}

export function normalizeFalsy(
  value: Primitive | Primitive[],
  emptyMessage = 'not set'
): string {
  if (
    isNil(value) ||
    (isArray(value) && !value.length) ||
    (isString(value) && value === '')
  ) {
    return emptyMessage
  }

  return isArray(value) ? value.join(', ') : String(value)
}

export function centsToDollars(cents: number): number {
  return cents / 100
}

export function dollarsToCents(dollars: number): number {
  return dollars * 100
}

export type BracketTypes = 'none' | 'rounded'
export const replaceBrackets = (
  string: string,
  brackets: BracketTypes
): string => {
  const startBracket = brackets === 'none' ? '' : '('
  const endBracket = brackets === 'none' ? '' : ')'

  return string.replace('[', startBracket).replace(']', endBracket)
}
