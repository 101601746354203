
import { defineComponent, onMounted } from 'vue'

import LayoutHandler from './layouts/LayoutHandler.vue'

import { useAuthStore } from './store/auth'

export default defineComponent({
  name: 'App',
  components: { LayoutHandler },
  setup() {
    const authStore = useAuthStore()

    onMounted(() => {
      console.info('User access scopes: ', authStore.scopes)
    })
  },
})
