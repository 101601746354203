import { defineStore } from 'pinia'

import router from '@/router'

import { removeToken, setToken } from '@/api'
import * as authApi from '@/api/auth'

import { parseErrors } from '@/helpers/error-parsers'
import { LsGlobalKeys, lsRemove, lsSave } from '@/helpers/local-storage'
import { handleCommonModuleState } from '@/helpers/store-state'
import { showToast } from '@/helpers/messages'

import { getInitialAuthState } from './state'

export const useAuthStore = defineStore({
  id: 'auth-store',
  state: getInitialAuthState,
  actions: {
    async test() {
      this.session?.scopes.push('update_trips')
    },

    async signIn(signInData: { email: string; password: string }) {
      try {
        this.$patch(state => {
          handleCommonModuleState('start', state.signInModule)
        })

        const session = (await authApi.signIn(signInData)).data.data

        this.session = session
        setToken(session.token)
        lsSave(LsGlobalKeys.AUTH, session)

        router.replace({ path: '/' })

        this.$patch(state => {
          handleCommonModuleState('success', state.signInModule)
        })
      } catch (e) {
        const { error, validationErrors: vErrors } = parseErrors(e)

        this.$patch(state => {
          handleCommonModuleState('error', state.signInModule, vErrors || error)
        })

        if (!vErrors) showToast({ type: 'error', message: error })
      }
    },
    signOut() {
      router.replace({ path: '/login' })
      this.$patch({ ...getInitialAuthState(), session: null })

      removeToken()
      lsRemove(LsGlobalKeys.AUTH)

      setTimeout(() => location.reload(), 0)
    },
  },
  getters: {
    isAuthenticated(state) {
      return Boolean(state.session?.token && state.session?.user)
    },
    scopes(state) {
      return state.session?.scopes || []
    },
  },
})
