import { Router } from 'vue-router'

import { useAuthStore } from '@/store/auth'

// Workaround, because no NavigationGuardWithThis export inside the `vue-router` package
type Guard = Parameters<Router['beforeEach']>[0]

const authMiddleware: Guard = to => {
  const isAuthenticated = useAuthStore().isAuthenticated

  const [homePath, loginPath] = ['/', '/login']
  const guesRoutes = ['Login']

  const pageIsInGuestMode = guesRoutes.includes(to.name as string)

  if (!isAuthenticated && !pageIsInGuestMode) {
    return { path: loginPath }
  }

  if (isAuthenticated && pageIsInGuestMode) {
    return { path: homePath }
  }
}

export default authMiddleware
